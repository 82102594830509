@import url('./font.css');
/* @import url('https://fonts.googleapis.com/css?family=Avenir:400,600,700'); */

* {
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  font-size: 0.9rem;
  overflow-x: hidden;
  color: #393939;
  background-color: #fff;
  font-family: 'Avenir', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
  overflow: hidden;
}

.h-100{
  height: 100%!important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
header,
section {
  margin: 0;
}

button,
input {
  border: none;
  outline: none;
}

p,
textarea {
  white-space: pre-line;
}

section {
  width: 100%;
  margin: 0;
}

.p-r {
  position: relative !important;
}

.p-abs {
  position: absolute !important;
}

.right-0 {
  right: -64px !important;
}

.top-0 {
  top: 0px !important;
}

.pd-l-auto {
  padding-left: auto !important;
}
.pd-r-0 {
  padding-right: 0px !important;
}
.pd-y-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.pd-l-7 {
  padding-left: 7px !important;
}
.pd-5 {
  padding: 5px !important;
}
.pd-7 {
  padding: 7px !important;
}
.pd-10 {
  padding: 10px !important;
}
.pd-b-5 {
  padding: 5px !important;
}
.no-padding {
  padding: 0 !important;
}
.p-5 {
  padding: 5px 5px 2px 5px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-10-20 {
  padding: 10px 20px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-y-10 {
  padding: 15px 0px;
}
.p-x-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.align-base{
  align-items: baseline;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-r-40 {
  padding-right: 40px !important;
}
.p-r-60 {
  padding-right: 60px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-t-23 {
  padding-top: 23px !important;
}

.no-margin {
  margin: 0 !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my--10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mx--10 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-20 {
  margin: 20px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-l-50 {
  margin-left: 50px !important;
}
.m-l-60 {
  margin-left: 60px !important;
}
.m-l-auto {
  margin-left: auto !important;
}
.m-r-auto {
  margin-right: auto !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-r-40 {
  margin-right: 40px !important;
}
.m-x-40 {
  margin-right: 40px !important;
  padding-left: 40px !important;
}
.m-r-2- {
  margin-right: 20px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t--20 {
  margin-top: -20px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-80 {
  margin-top: 80px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-auto {
  margin-top: auto !important;
}
.m-b-3 {
  margin-bottom: 3px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}

.no-border {
  border: none !important;
}
.no-wrap {
  flex-wrap: nowrap !important;
}
.wrap {
  flex-wrap: wrap !important;
}
.flex {
  display: flex !important;
}
.flex-between {
  display: flex !important;
  justify-content: space-between;
}

.flex-basis-48{
  flex-basis: 48%;
}

.flex-basis-65{
  flex-basis: 65%;
}

.flex-basis-60{
  flex-basis: 60%;
}

.flex-basis-100{
  flex-basis: 100%;
}

.flex-basis-20{
  flex-basis: 20%;
}

.flex-basis-40{
  flex-basis: 40%;
}

.flex-basis-30{
  flex-basis: 30%;
}

.flex-basis-25{
  flex-basis: 25%;
}

.flex-basis-15{
  flex-basis: 15%;
}

.flex-basis-10{
  flex-basis: 10%;
}

.flex-basis-5{
  flex-basis: 5%;
}

.signature_img{
  width: 200px;
  height: auto;
}

.flex-col {
  flex-direction: column !important;
}
.flex-h-end {
  justify-content: end !important;
}
.flex-h-center {
  justify-content: center !important;
}
.flex-v-center {
  align-items: center !important;
}
.flex-direction-column {
  flex-direction: column !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.flex-v-end {
  align-items: end !important;
}
.pointer {
  cursor: pointer !important;
}
.underline {
  text-decoration: underline !important;
}
.center-text {
  text-align: center !important;
}
.right-text {
  text-align: right !important;
}
.space-between {
  justify-content: space-between !important;
}
.space-around {
  justify-content: space-around !important;
}
.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
.inline-block {
  display: inline-block !important;
}
.block{
  display: block;
}
.cashInput{
  height: 44px;
  border: 1px solid black;
  border-radius: 4px;
  box-shadow: 1px 1px 6px 0px;
  width: 100%;
}

.form-bg-shadow{
  box-shadow: 0px 2px 8px 0px;
    border-radius: 8px;
}
.bold-text {
  font-weight: bold !important;
}
.bolder-text {
  font-weight: 900 !important;
}
.border-bottom-gray-1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-top-green-4 {
  border-top: 4px solid #1c811c !important;
}
.dev-border{
  border: 1px solid red;
}

.btn-border{
  border: 1px solid green;
}

.edit-border{
  border: 3px solid green;
}
.max-w-400 {
  max-width: 400px !important;
}
.max-w-700 {
  max-width: 700px !important;
}
.col-a-8 {
  width: 66.66%;
}
.col-a-7 {
  width: 58.33%;
}
.col-a-5 {
  width: 41.65%;
}

.col-a-4 {
  width: 16.66%;
}
.col-a-6 {
  width: 50%;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-half,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  display: inline-block;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.65%;
}
.col-6,
.col-half {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.63%;
}
.col-12,
.w-100 {
  width: 100% !important;
  position: relative;
}
.w-98 {
  width: 98% !important;
}

.w-90 {
  width: 90% !important;
}

.w-75 {
  width: 75% !important;
}

.w-25 {
  width: 25%;
  padding-left: 100px;
}

.w-25r {
  width: 25%;
}

.w-200 {
  width: 200px;
}

.w-80 {
  width: 80% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50%;
  padding-left: 230px;
}

.w-48 {
  width: 48% !important;
}

.w-50 {
  width: 50% !important;
}

.w-30 {
  width: 30% !important;
}

.bt1 {
  text-align: center;
  display: flex;
  justify-content: center;
}

.font-size-1 {
  font-size: 1rem !important;
}

.bt2 {
  flex: 1;
}
.w1 {
  width: 5%;
}
.row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
}

.content {
  /* max-width: 1300px; */
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
}

.content1 {
  /* max-width: 1300px; */
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;
}

footer {
  position: relative;
  background-color: #454545;
  color: #ddd;
  height: 58px;
}

.tr-green{
  background-color: #d5fad5;
}

.picture-link {
  text-decoration: underline;
  color: blue;
}

div.banner {
  width: 100%;
  padding: 130px 0 100px;
  background-size: cover;
  background-blend-mode: luminosity;
  background-image: url('../images/the-city-gate-of-abuja\ \(1\).jpg');
  background-color: #1c811c55;
  background-position-y: 20%;
}

div.banner .login {
  margin: 0 auto;
  max-width: 600px;
  padding: 40px;
  position: unset;
  border-radius: 10px;
  background-color: #fffffff0;
}
div.login {
  display: flex;
}
div.login .coat-of-arm {
  border-right: 1px solid #c6c6c6;
  padding-top: 15px;
  padding-right: 40px;
  width: 270px;
}
div.login .coat-of-arm img {
  width: 140px;
  height: auto;
}
div.login .coat-of-arm h2 {
  font-size: 1.58rem;
  font-family: sans-serif;
  line-height: 1.5rem;
  font-weight: 700;
}
div.login .coat-of-arm h4 {
  font-size: 1.25rem;
  font-weight: 400;
}
div.login .form {
  padding-left: 40px;
  width: 100%;
  text-align: center;
}
.login-form-group {
  width: 100%;
  margin-bottom: 20px;
}
.login-form-group {
  width: 100%;
  margin-bottom: 15px;
}
.login-form-group input {
  width: 100%;
  padding: 13px;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.9rem;
  color: #777;
}
div.login .form button,
.button {
  width: 100%;
  color: #fff;
  padding: 13px;
  background-color: #017d34;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.3s ease-out;
}

.overflow-table{
  overflow-x:auto;
  display:block;
}

table, th, td {
  border:1px solid black;
  border-collapse: collapse;
}

.table_button {
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  background-color: #017d34;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.3s ease-out;
}
div.login .form button:hover {
  background-color: #017d3488;
}
.bg_green {
  background-color: #d5ffd5;
}
.intro {
  max-width: 800px;
  margin: 30px auto 10px;
}
div.dept-news {
  background-color: #ffffff;
  box-shadow: 5px 3px 22px #958e8e29;
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}
.item-shadow {
  box-shadow: 5px 3px 22px #958e8e29;
}

div.dept-news h4 {
  background-color: #1c811c;
  color: #ffffff;
  padding: 5px 10px 2px;
  display: inline-block;
  position: relative;
  right: 20px;
}
.headline {
  align-items: center;
  border-bottom: 1px solid #c6c6c6;
  padding-bottom: 20px;
}
.headline:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.headline .date {
  background-color: #1c811c1f;
  padding: 5px 10px;
  text-align: center;
}
.headline .date .m {
  font-size: 0.7rem;
}
.headline .date .d {
  font-size: 1.4rem;
}
.headline .title {
  margin-left: 10px;
}
div.bottom {
  border-left: 1px solid #c6c6c6;
  padding: 0 20px;
}
div.bottom h2 {
  color: #1c811c;
  font-family: sans-serif;
  margin-bottom: 20px;
}
div.bottom ol {
  padding-left: 20px;
}
div.bottom ul {
  padding: 0;
  list-style: none;
}

.status_ball{
  width: 20px;
  border-radius: 50%;
}

.bg-gray {
  color: darkgray;
  size: 15px;
}

.col-wite{
  color: white;
}
.bg-green {
  color: #066906;
  size: 15px;
}

.bg-amber {
  color: #ff9800;
  size: 15px;
}

.bg-red {
  color: #f44336;
  size: 15px;
}

.back-red{
  background-color: #f44336;
}

@media only screen and (max-width: 900px) {
  .col-8,
  .col-7,
  .col-6 {
    width: 100%;
    flex: 1 0 100%;
  }
  .col-5,
  .col-4,
  .col-3 {
    width: 50%;
    flex: 1 0 50%;
  }
}


  .page {
    size: 21cm 29.7cm;
    /* margin: 30mm 45mm 30mm 45mm; */
    padding: 20mm;
     /* change the margins as you want them to be. */
}


.status_active {
  background-color: #41821e;
}

.status_inactive {
  background-color: #aaaaaa;
}

.status_pending {
  background-color: yellow;
}

.status_draft {
  background-color: grey;
}

.status_rejected {
  background-color: red;
}

.status_badge {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 100%;
}

.h-35 {
  height: 35px !important;
}

.filter_badge_active {
  border-radius: 50px;
  background-color: #c6c6c6;
  border: 1px solid grey;
}

.filter_badge_inactive {
  border-radius: 50px;
  background-color: white;
}

.filter_badge_text {
  padding: 5px 15px;
}
@media only screen and (max-width: 600px) {
  .col-5,
  .col-4,
  .col-3 {
    width: 100%;
    flex: 1 0 100%;
  }
  div.banner {
    padding-top: 60px;
  }
  div.login {
    display: block;
    margin: 20px !important;
  }
  div.login .coat-of-arm {
    border-right: none;
    padding-bottom: 20px;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
  div.login .form {
    padding-left: 0;
  }
}

.ant-radio-checked .ant-radio-inner{
  border-color: green !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: green;
}

.ant-radio:hover .ant-radio-inner {
  border-color: green ;
}

.accordion-btn:hover {
  background-color: #ddd;
  color: #777;
  transition: all 0.6s ease-in-out;
}

/* Modal Styling */
.bg-modal {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #1c811c66;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 1000;
}

.modal-content {
  width: 580px;
  height: 350px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 6px #0000000a;
  text-align: center;
  padding: 10px;
}


.modal-content-large {
  width: 80%;
  height: auto;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 6px #0000000a;
  padding: 30px;
}

.bg-grey {
  background-color: #f0f0f0 !important;
}

.filter_box_border_b {
  border-bottom: 1px solid grey;
}

.custom-file-upload {
  border: 1px solid #ccc;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 2px;
  cursor: pointer;
}

.rounded{
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: green;
  color: white;
}

.rounded span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensures span fills the entire circle */
  height: 100%; /* Ensures span fills the entire circle */
  font-size: 40px; /* Adjust font size for filling width */
  overflow: hidden;
  white-space: nowrap;
}

.border-des{
  border: 1px solid green;
  border-radius: 50px;
}

.bg-bg-green {
  background-color: #066906;
  size: 15px;
}

.bg-bg-amber {
  background-color: #ff9800;
  size: 15px;
}

.bg-bg-red {
  background-color: #f44336;
  size: 15px;
}

.dotted-bottom{
  border-bottom: 3px dotted black;
}

.rounded-sm{
  border: 1px solid black;
  border-radius: 16px;
  cursor: pointer;

}

.rounded-sm:hover{
  background-color: #066906;
  color: white;
}

.rb-active{
  background-color: #066906;
  color: white;
}

.row-hover{

}

.row-hover:hover{
  background-color: #d5ffd5;
}

.view-det-pane {
  border-radius: 4px;
  border: 1px solid #3C7E2D;
  background: #FFF;
  box-shadow: 1px 4px 11px 0px rgba(206, 189, 228, 0.25);
}

.bot-bord {
  border-bottom: 1px solid #3C7E2D;
}

.img-avatar {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: 1px solid #3C7E2D;
}

.round-5{
  border-radius: 8px;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

/* style the accordion section  */
.accordion__section {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 10px 0;
  box-shadow: 0px 2px 6px #0000000a;
}

/* style the button that are used to open and close the accordion  */
.accordion {
  background-color: #1c811c;
  color: #fff;
  cursor: pointer;
  padding: 12px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: all 0.6s ease;
}

/* add background color on the button on hover and if it is active */
.accordion:hover,
.active {
  background-color: #1c811c99;
  border-bottom: 1px solid #1c811c;
}

/* style the accordion title */
.accordion__title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

/* style accordion icon  */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* style the accordion content */
.accordion__content {
  /* background-color: white; */
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* style the accordion content text */
.accordion__text {
  font-family: 'Open-Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}
